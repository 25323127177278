import { FC } from "react"

import { CopyText } from "../copyText"
import { Icon } from "src/components/common/icon"
import { create } from "src/helpers/bem"
import { useTranslation } from "src/hooks/translation"
import {
  mergeTopBannerStatus,
  useTopBannerStatus,
} from "src/states/topBannerStatus"
import { ContentfulRichTextData } from "src/types/contentful/autoservice"

import styles from "./TopBanner.module.scss"

const bem = create(styles, "TopBanner")

export type TopBannerProps = {
  content?: ContentfulRichTextData
}

export const TopBanner: FC<TopBannerProps> = ({ content }) => {
  const { isTopBannerClosed } = useTopBannerStatus()
  const { messages } = useTranslation()
  const translations = messages.components.common.modal

  if (!content || isTopBannerClosed) return null

  const onClose = () => mergeTopBannerStatus({ isTopBannerClosed: true })

  return (
    <div className={bem()}>
      <div className={bem("content")}>
        <CopyText content={content} alignment={"center"} />
      </div>

      <button
        className={bem("close")}
        aria-label={translations.buttons.close.label}
        onClick={onClose}
      >
        <Icon className={bem("icon")} name="cancel" />
      </button>
    </div>
  )
}
