import { ContentfulCommonEntryData } from "src/types/contentful"

/**
 * Published content can contain references to unpublished draft content.
 * We don't want to attempt to render this content because it is empty.
 * We can identify such entries by the absence of `entry.sys.contentType`.
 */
export function isNotBlank(section?: ContentfulCommonEntryData) {
  return section?.sys?.contentType
}
