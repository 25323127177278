import { FC } from "react"

import { BaseLink } from "src/components/common/base-link"
import { Icon } from "src/components/common/icon"
import { screenBreakpoints } from "src/const/screenBreakpoints"
import { create } from "src/helpers/bem"
import { Media } from "src/helpers/media"

import styles from "./Breadcrumbs.module.scss"
import { Breadcrumb } from "./types"

const bem = create(styles, "Breadcrumbs")

type BreadcrumbsMobileProps = Breadcrumb

export const BreadcrumbsMobile: FC<BreadcrumbsMobileProps> = ({
  label,
  url,
}) => {
  return (
    <Media maxWidth={screenBreakpoints.md}>
      <nav aria-label="breadcrumb" className={bem("container")}>
        <ol className={bem("list")}>
          <li className={bem("item")}>
            <Icon
              name="navPrev"
              className={bem("chevron-icon")}
              aria-hidden="true"
            />
            <BaseLink href={url}>{label}</BaseLink>
          </li>
        </ol>
      </nav>
    </Media>
  )
}
