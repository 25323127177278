import { EntrySkeletonType } from "contentful"

import { Entry, PartialDeepOrUndefined } from "../contentful-type-patches"

import {
  TypeItemPromotionHeaderSkeleton,
  TypeSectionFeaturesSkeleton,
  TypeSectionServiceSelectionSkeleton,
} from "./generated"

export function isTypeSectionServiceSelection(
  entry?: PartialDeepOrUndefined<Entry<EntrySkeletonType>>,
): entry is Entry<TypeSectionServiceSelectionSkeleton> {
  return entry?.sys?.contentType?.sys?.id === "sectionServiceSelection"
}

export function isTypeSectionFeatures(
  entry?: PartialDeepOrUndefined<Entry<EntrySkeletonType>>,
): entry is Entry<TypeSectionFeaturesSkeleton> {
  return entry?.sys?.contentType?.sys?.id === "sectionFeatures"
}
export function isTypeItemPromotionHeader(
  entry?: PartialDeepOrUndefined<Entry<EntrySkeletonType>>,
): entry is Entry<TypeItemPromotionHeaderSkeleton> {
  return entry?.sys?.contentType?.sys?.id === "itemPromotionHeader"
}
