import { FC, PropsWithChildren, ReactNode } from "react"

import {
  TopBanner,
  TopBannerProps,
} from "src/components/cck/elements/topBanner/TopBanner"
import { Footer as ContentfulFooter } from "src/components/cck/sections/footer"
import { FooterProps } from "src/components/cck/sections/footer"
import { Breadcrumbs } from "src/components/core/breadcrumbs"
import { useGetBreadcrumbsFromUrl } from "src/components/core/breadcrumbs/hooks/useGetBreadcrumbsFromUrl"
import { Footer } from "src/components/core/footer"
import { create } from "src/helpers/bem"

import { useFlow } from "src/hooks/useFlow"
import { HeaderNavigationProps } from "src/types/HeaderNavigationProps"

import styles from "./BasicLayout.module.scss"
import { BasicLayoutHeader } from "./BasicLayoutHeader"

const bem = create(styles, "BasicLayout")

type BasicLayoutProps = PropsWithChildren<{
  footerSection?: FooterProps | null
  headerNavigation?: HeaderNavigationProps[] | null
  hideBreadcrumbs?: boolean
  topBannerItem?: TopBannerProps
  hideFooter?: boolean
  stickyFooter?: ReactNode
}>

export const BasicLayout: FC<BasicLayoutProps> = ({
  headerNavigation,
  footerSection,
  topBannerItem,
  hideBreadcrumbs,
  hideFooter,
  stickyFooter,
  children,
}) => {
  const breadcrumbs = useGetBreadcrumbsFromUrl()
  const { isIframeBookingFlow } = useFlow()

  function renderFooter() {
    if (isIframeBookingFlow) return null
    if (footerSection) return <ContentfulFooter {...footerSection} />
    return <Footer />
  }

  return (
    <div className={bem()}>
      {topBannerItem && <TopBanner {...topBannerItem} />}
      <BasicLayoutHeader headerNavigation={headerNavigation} />

      <main id="main" className={bem("main")}>
        <div className={bem("container")}>
          {!hideBreadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}

          {children}
        </div>

        {!hideFooter && renderFooter()}
      </main>
      {stickyFooter}
    </div>
  )
}
