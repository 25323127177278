import { sub } from "date-fns/sub"
import { GetServerSideProps } from "next"

import { cckContentPropsMapping } from "src/components/contentful/helpers/cckContentPropsMapping"
import { throwServerError } from "src/components/pages/error/throwServerError"

import { HomePageProps, HomePage } from "src/components/pages/home/HomePage"

import { ROUTES, serviceFlowSteps } from "src/config"
import { accountApi } from "src/helpers/autoservice-account-backend"

import {
  fetchContentfulItemTopBanner,
  fetchContentfulPage,
  fetchContentfulSectionHeaderNavigation,
} from "src/helpers/contentful/page"
import { getUTCDateWithoutSeconds } from "src/helpers/date"
import { env } from "src/helpers/env"
import { getFooter } from "src/helpers/getFooter"
import { getRecommendationFromBookings } from "src/helpers/getRecommendationFromBookings"
import { isUpcomingBooking } from "src/helpers/isUpcomingBooking"
import { getAuth } from "src/helpers/next-auth/server"

import { AccountApiBookingResponse } from "src/types/autoservice-account-backend"

const notAcceptedStatus: AccountApiBookingResponse["bookingStatus"][] = [
  "CANCELED",
  "FAILED",
  "FINALIZED",
]

async function getAccountBookings(
  accountId: string,
  amazonTraceId?: string,
): Promise<AccountApiBookingResponse[] | null> {
  try {
    const oneYearAgo: Date = sub(new Date(), { years: 1 })
    const response = await accountApi.getAccountBookings(accountId, {
      dateFrom: getUTCDateWithoutSeconds(oneYearAgo),
    })

    // Show only appointments with the correct status
    const bookings =
      response.content?.filter(
        (booking) => !notAcceptedStatus.includes(booking.bookingStatus),
      ) || []

    return bookings || null
  } catch (error) {
    throwServerError({
      amazonTraceId,
      error,
      source: "getAccountBookings",
      logMessage:
        "Something went wrong with the accountApi.getAccountBookings API request",
    })
    return null
  }
}

export const getServerSideProps: GetServerSideProps<HomePageProps> = async (
  context,
) => {
  const { preview } = context
  const amazonTraceId = context.req.headers["x-amzn-trace-id"] as string

  const homepagePath = ROUTES.index
  const servicesPath = ROUTES.serviceFlow({
    flowStep: serviceFlowSteps.service,
  })

  const googleMapsApiKey = env("FE_GOOGLE_API_KEY")
  const environment = env("ENVIRONMENT")

  const [
    contentfulPage,
    contentfulServicesPage,
    contentfulHeaderNavigation,
    contentfulTopBanner,
    auth,
  ] = await Promise.all([
    fetchContentfulPage({
      path: homepagePath,
      preview,
      space: "cck",
    }),
    fetchContentfulPage({
      path: servicesPath,
      preview,
      space: "autoservice",
    }),
    fetchContentfulSectionHeaderNavigation({ preview }),
    fetchContentfulItemTopBanner({ preview }),
    getAuth(context),
  ])

  if (!contentfulPage) {
    return {
      notFound: true,
    }
  }

  const headerNavigation = cckContentPropsMapping.sectionHeaderNavigation(
    contentfulHeaderNavigation,
  )
  const footerSection = await getFooter(contentfulPage, preview)
  const topBannerItem =
    cckContentPropsMapping.itemTopBanner(contentfulTopBanner)

  const accountBookings = auth?.user
    ? await getAccountBookings(auth.user.id, amazonTraceId)
    : null

  const recommendedBooking = getRecommendationFromBookings(
    accountBookings || [],
  )

  const upcomingBookings = accountBookings?.filter(isUpcomingBooking)

  return {
    props: {
      preview: preview || false,
      contentfulPage,
      topBannerItem,
      headerNavigation,
      footerSection,
      auth,
      upcomingBookings: upcomingBookings || [],
      recommendedBooking,
      environmentVariables: {
        googleMapsApiKey,
        environment,
      },
    },
  }
}

export default HomePage
