import {
  AccountBookingInfo,
  BookingGarage,
  BookingInfo,
} from "src/helpers/autoservice-account-backend/types"
import { Inquiry } from "src/states/serviceFlow/inquiryActions"
import { AccountApiBookingResponse } from "src/types/autoservice-account-backend"
import { ContentfulServicesWithCategories } from "src/types/contentful/autoservice"

import { getBookingServicesWithPrices } from "./getBookingServicesWithPrices"

export type MapApiBookingToBookingInfo = (
  booking: AccountApiBookingResponse,
  services: ContentfulServicesWithCategories,
) => BookingInfo

export const mapApiBookingToBookingInfo: MapApiBookingToBookingInfo = (
  booking,
  allServices,
) => {
  const timeslot = booking.at ? new Date(booking.at) : null

  const { services, priceBeforeDiscount, priceAfterDiscount, discountAmount } =
    getBookingServicesWithPrices(booking, allServices)

  const bookingCard: AccountBookingInfo = {
    id: booking.id || "",
    timeslot: timeslot,
    orderId: booking.concardisPaymentInfo?.pitstopBookingNumber || "",
    priceBeforeDiscount,
    priceAfterDiscount,
    discountAmount,
    services,
    garage: {
      id: booking.garage?.id || "",
      name: booking.garage?.name || "",
      street: booking.garage?.address || "",
      city: booking.garage?.city || "",
      postalCode: booking.garage?.postalCode || "",
      phoneNumber: booking.garage?.phoneNumber || "",
      coordinates: {
        latitude: booking.garage?.coordinates?.latitude || 0,
        longitude: booking.garage?.coordinates?.longitude || 0,
      },
      externalId: booking.garage?.externalId || "",
      serviceHoursV2: [], // we don't need this info now
      email: "", // we don't need this info now
      dmsProviderType: booking.garage
        .dmsProviderType as BookingGarage["dmsProviderType"],
    },
    car: {
      id: booking.vehicle?.customerCarId || "",
      hsn: booking.vehicle?.hsn || "",
      tsn: booking.vehicle?.tsn || "",
      make: booking.vehicle?.make || "",
      model: booking.vehicle?.model || "",
      licensePlate: booking.vehicle?.licensePlate || "",
      licensePlateType: booking.vehicle?.licensePlateType || "",
    },
    customer: {
      id: booking.customerAccount?.id || "",
      firstName: booking.customerAccount?.firstName || "",
      lastName: booking.customerAccount?.lastName || "",
      email: booking.customerAccount?.email || "",
      phoneNumber: booking.customerAccount?.phoneNumber || "",
      street: booking.customerAccount?.street || "",
      houseNumber: booking.customerAccount?.houseNumber || "",
      addressAddition: booking.customerAccount?.addressAddition || "",
      city: booking.customerAccount?.city || "",
      postalCode: booking.customerAccount?.postalCode || "",
      country: booking.customerAccount?.country || "",
    },
    status: booking.bookingStatus,
    promoCode: booking.promoCode,
    inquiry: booking.inquiry as Inquiry,
    customerActionRequired: booking.customerActionRequired,
    offerValidUntil: booking.offerValidUntil,
    isReplacementCarRequested: booking.isReplacementCarRequested,
    servicePayments: booking.servicePayments,
  }

  return bookingCard
}
