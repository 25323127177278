import {
  ContentfulPageData,
  ContentfulSectionServiceSelectionData,
  ContentfulServicesWithCategories,
} from "src/types/contentful/autoservice"
import { isTypeSectionServiceSelection } from "src/types/contentful/autoservice/typeGuards"

export const getContentfulServicesWithCategories = (
  contentfulData: ContentfulPageData | null,
) => {
  const categories = (contentfulData?.fields?.sections ?? []).find((section) =>
    isTypeSectionServiceSelection(section),
  ) as ContentfulSectionServiceSelectionData

  // filtering done to avoid undefined type assertion on Contentful Data Model type.
  const serviceCategories = (
    categories?.fields?.serviceSelection?.fields?.serviceCategories ?? []
  ).filter(Boolean)

  return (serviceCategories ?? []).reduce(
    (services, category) => [
      ...services,
      ...(category?.fields?.services || []).map((service) => ({
        ...service?.fields,
        category: {
          ...category?.fields,
          additionalServices: (category?.fields?.additionalServices || [])
            .map((additionalService) => additionalService?.fields)
            .filter(Boolean),
        },
      })),
    ],
    [] as ContentfulServicesWithCategories,
  )
}
