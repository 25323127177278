import {
  BookingAdditionalServices,
  BookingService,
} from "src/helpers/autoservice-account-backend/types"
import { AccountApiBookingResponse } from "src/types/autoservice-account-backend"
import { ContentfulServicesWithCategories } from "src/types/contentful/autoservice"

// This function takes booking services and merge it with Contentful data
// in order to get services with correct names, icons and prices (incl. additional services).
// It also returns the total payment amount.

export const getBookingServicesWithPrices = (
  booking: AccountApiBookingResponse,
  contentfulServices: ContentfulServicesWithCategories,
) => {
  const {
    servicePayments,
    priceAfterDiscount,
    priceBeforeDiscount,
    discountAmount,
  } = booking

  const services: BookingService[] =
    servicePayments?.map((servicePayment) => {
      const additionalServices: BookingAdditionalServices[] = []
      const categoryData = contentfulServices.find(
        (contentfulService) =>
          servicePayment.internalServiceId ===
          contentfulService?.internalServiceId,
      )

      const category = categoryData?.category
      category?.additionalServices?.forEach((contentfulAdditionalServices) => {
        const additionalServicePayment =
          servicePayment.additionalServices?.find(
            (additionalService) =>
              additionalService.internalAdditionalServiceId ===
              contentfulAdditionalServices?.internalAdditionalServiceId,
          )

        if (additionalServicePayment) {
          additionalServices.push({
            id: contentfulAdditionalServices?.internalAdditionalServiceId || "",
            name: contentfulAdditionalServices?.title || "",
            priceBeforeDiscount:
              additionalServicePayment.priceBeforeDiscount || 0,
            priceAfterDiscount:
              additionalServicePayment.priceAfterDiscount || 0,
            discountAmount: additionalServicePayment.discountAmount || 0,
          })
        }
      })

      return {
        id:
          category?.internalServiceCategoryId ||
          servicePayment.internalCategoryId,
        internalServiceId: servicePayment?.internalServiceId ?? "",
        icon: category?.icon || "wrench",
        name: servicePayment?.name ?? category?.title ?? "",
        priceBeforeDiscount: servicePayment?.priceBeforeDiscount || 0,
        priceAfterDiscount: servicePayment?.priceAfterDiscount || 0,
        discountAmount: servicePayment.discountAmount || 0,
        additionalServices,
        packageAdditionalServiceIds:
          (categoryData?.packageAdditionalServiceIds as string[]) || null,
      }
    }) || []

  return { services, priceBeforeDiscount, priceAfterDiscount, discountAmount }
}
