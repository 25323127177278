import { BookingInfo } from "./autoservice-account-backend/types"
import { daysBetween } from "./date"

export const isValidInspectionOffer = (
  customerActionRequired: BookingInfo["customerActionRequired"],
  offerValidUntil: string,
) => {
  return (
    customerActionRequired === "ACCEPT_OR_DECLINE_OFFER" &&
    !!daysBetween(new Date(offerValidUntil))
  )
}
