import { MetaProps } from "src/components/core/meta"
import { ContentfulPageData } from "src/types/contentful/autoservice"

export function mapContentfulPageMetadata(
  data: ContentfulPageData | null,
): MetaProps {
  const meta = data?.fields?.meta
  return {
    title: meta?.fields?.title,
    description: meta?.fields?.description,
    ogImage: meta?.fields?.ogImage?.fields?.file?.url,
    ogUrl: meta?.fields?.url,
    robots: meta?.fields?.robots,
    canonicalPath: meta?.fields?.canonicalUrl,
  }
}
