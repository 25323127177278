import { FC } from "react"

import { create } from "src/helpers/bem"
import { getContentType } from "src/helpers/contentful/getContentType"
import { isNotBlank } from "src/helpers/contentful/isNotBlank"
import { ContentfulPageData } from "src/types/contentful/autoservice"

import styles from "./ContentfulPage.module.scss"
import { ContentfulSection } from "./ContentfulSection"

const bem = create(styles, "ContentfulPage")

export const ContentfulPage: FC<{
  data: ContentfulPageData
}> = ({ data }) => {
  return (
    <>
      {data?.fields?.sections?.filter(isNotBlank).map((sectionData) => (
        <section
          key={sectionData?.sys?.id}
          data-cy={getContentType(sectionData)}
          className={bem()}
        >
          <ContentfulSection data={sectionData} />
        </section>
      ))}
    </>
  )
}
