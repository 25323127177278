import { useEffect } from "react"

import { AuthData } from "src/helpers/next-auth/server"
import { sendTrackingEvent } from "src/helpers/tracking/sendTrackingEvent"

export const useUserIdentifyTrackingEvents = (auth: AuthData) => {
  useEffect(() => {
    if (auth.user?.id) {
      const { providerId, providerUserId, ...userData } = auth.user

      sendTrackingEvent.authentication({
        gaEvent: {
          action: "identify",
          value: { ...userData, accountId: userData.id },
        },
      })
    }
    // Should be called only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
