import { useRouter } from "next/router"

import { convertToGermanCharacters } from "../helpers/convertToGermanCharacters"
import { Breadcrumb } from "../types"
import { ROUTES } from "src/config"
import { useTranslation } from "src/hooks/translation"

export const useGetBreadcrumbsFromUrl = (): Breadcrumb[] => {
  const { messages } = useTranslation()
  const translations = messages.components.core.breadcrumbs

  const router = useRouter()

  let pathParts = router.asPath
    .split(/[?#]/)[0]
    .split("/")
    .filter((path, index) => index === 0 || !!path)

  // For refer friends content pages we need to show only 2 items
  if (router.asPath.includes(ROUTES.referFriends.index)) {
    pathParts = pathParts.slice(0, 2)
  }

  return pathParts.map((path, index) => {
    const previousParts = pathParts.slice(0, index)

    return {
      label: index === 0 ? translations.home : convertToGermanCharacters(path),
      url:
        previousParts?.length > 0
          ? `${previousParts.join("/")}/${path}`
          : `/${path}`,
    }
  })
}
