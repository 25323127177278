import { FC, useState } from "react"

import { Icon } from "src/components/common/icon"
import { BookingCard } from "src/components/modules/bookingCard"
import { screenBreakpoints } from "src/const/screenBreakpoints"
import { BookingInfo } from "src/helpers/autoservice-account-backend/types"

import { create } from "src/helpers/bem"
import { Media } from "src/helpers/media"
import { useTranslation } from "src/hooks/translation"

import styles from "./NextAppointmentHeader.module.scss"

const bem = create(styles, "NextAppointmentHeader")

export type NextAppointmentHeaderProp = {
  firstName: string
  nextAppointment?: BookingInfo
  recommendation?: BookingInfo
}

export const NextAppointmentHeader: FC<NextAppointmentHeaderProp> = ({
  firstName,
  nextAppointment,
  recommendation,
}) => {
  const [closed, setClosed] = useState<boolean>(false)
  const { messages } = useTranslation()

  const translations = messages.components.core.nextAppointmentHeader

  if (closed) {
    return null
  }

  const appointment = nextAppointment ?? recommendation

  const isRecommendation = !nextAppointment && !!recommendation

  const modifiers = {
    "no-appointments": !appointment,
  }

  return (
    <div className={bem()}>
      <div className={bem("content", modifiers)}>
        <div className={bem("greeting-wrapper", modifiers)}>
          <p className={bem("greeting")}>
            {`${translations.greeting} ${firstName}`}
          </p>
          {isRecommendation && (
            <div className={bem("recommendation")}>
              <p className={bem("title")}>
                {translations.recommendation.title}
              </p>
              <p className={bem("description")}>
                {translations.recommendation.description}
              </p>
            </div>
          )}
          {!appointment && (
            <p className={bem("no-appointments")}>
              {translations.noAppointments}
            </p>
          )}
        </div>
        {appointment && (
          <div className={bem("upcoming-booking-wrapper")}>
            {!!nextAppointment && (
              <Media maxWidth={screenBreakpoints.lg}>
                <h2 className={bem("upcoming-booking-title")}>
                  {translations.nextAppointment}
                </h2>
              </Media>
            )}
            <div className={bem("booking-card-wrapper")}>
              <BookingCard
                {...appointment}
                isRecommendation={isRecommendation}
              />
            </div>
          </div>
        )}
        <button className={bem("close-button")} onClick={() => setClosed(true)}>
          <Icon className={bem("close-button__icon")} name="cancel" />
        </button>
      </div>
    </div>
  )
}
