import isEmpty from "lodash/isEmpty"
import { useRouter } from "next/router"
import { signOut } from "next-auth/react"
import { FC, useEffect } from "react"

import { ContentfulPage } from "src/components/contentful/ContentfulPage"
import { Meta } from "src/components/core/meta"
import { NextAppointmentHeader } from "src/components/core/NextAppointmentHeader"
import { BasicLayout } from "src/components/layouts/basic"
import { ROUTES, serviceFlowSteps } from "src/config"
import { getContentfulServicesWithCategories } from "src/helpers/contentful/getContentfulServicesWithCategories"
import { mapContentfulPageMetadata } from "src/helpers/contentful/mapContentfulPageMetadata"
import { daysBetween } from "src/helpers/date"
import { isValidInspectionOffer } from "src/helpers/isValidInspectionOffer"
import { mapApiBookingToBookingInfo } from "src/helpers/mapApiBookingToBookingInfo"
import { useAuth } from "src/helpers/next-auth/client"
import { useTranslation } from "src/hooks/translation"
import { useNotifications } from "src/hooks/useNotifications"
import { useUserIdentifyTrackingEvents } from "src/hooks/useUserIdentifyTrackingEvents"
import { removeAllNotificationToast } from "src/states/notificationToasts"
import { useOfferNotification } from "src/states/offerNotification"
import { useServiceFlowData } from "src/states/serviceFlow"
import settingsActions from "src/states/serviceFlow/settingsActions"
import { AccountApiBookingResponse } from "src/types/autoservice-account-backend"
import { ContentfulPageData } from "src/types/contentful/autoservice"
import { SharedPageProps } from "src/types/SharedPageProps"

export interface HomePageProps extends SharedPageProps {
  contentfulPage: ContentfulPageData
  upcomingBookings: AccountApiBookingResponse[]
  recommendedBooking: AccountApiBookingResponse | null
}

export const HomePage: FC<HomePageProps> = ({
  contentfulPage,
  upcomingBookings = [],
  recommendedBooking,
  headerNavigation,
  footerSection,
  topBannerItem,
}) => {
  const auth = useAuth({ required: false })
  const firstName = auth?.user?.firstName ?? ""

  const router = useRouter()
  const { notify } = useNotifications()
  const { offerNotificationInteracted } = useOfferNotification()

  useServiceFlowData()
  useUserIdentifyTrackingEvents(auth)

  const { messages, formatMessage } = useTranslation()
  const translations = messages.pages.home
  const accountTranslations = messages.pages.account.myAccount

  const inspectionOffer = upcomingBookings.find((booking) =>
    isValidInspectionOffer(
      booking.customerActionRequired,
      booking.offerValidUntil ?? "",
    ),
  )

  const services = getContentfulServicesWithCategories(contentfulPage)

  const bookingsInfo = upcomingBookings.map((booking) =>
    mapApiBookingToBookingInfo(booking, services),
  )

  const recommendation = recommendedBooking
    ? mapApiBookingToBookingInfo(recommendedBooking, services)
    : undefined

  const offerValidityDaysLeft = daysBetween(
    new Date(inspectionOffer?.offerValidUntil ?? ""),
  )

  const daysLeft =
    offerValidityDaysLeft === 1
      ? translations.oneDay
      : `${offerValidityDaysLeft} ${translations.days}`

  const offerNotificationFormattedText =
    formatMessage(translations.offerNotificationText, {
      daysLeft,
    }) + translations.offerLink

  useEffect(() => {
    settingsActions.clearFlow()
  }, [])

  useEffect(() => {
    if (router?.query?.loggedOut || router?.query?.deletionRequest) {
      const { query } = router
      const param = query.deletionRequest ? "deletionRequest" : "loggedOut"
      if (auth?.user) {
        // Logout was not successful.
        // This can happen (very sporadically) when a session GET request (/api/auth/session) and the signout (/api/auth/signout) POST request
        // occur nearly at the same time (race condition).
        // The signout clears the session (clear cookies) while the GET request restores the session by returning the previous session cookies.
        // By calling signout again this situation can be resolved.
        signOut({ callbackUrl: `/?${param}=true` })
      } else {
        const { deletionRequest, success, contact } =
          accountTranslations.signOut

        const notificationData = query.deletionRequest
          ? {
              content: deletionRequest + contact,
              autoclose: false,
              link: {
                link: ROUTES.contact,
              },
            }
          : { content: success }

        notify({
          variant: "success",
          ...notificationData,
        })
      }

      router.replace(router.pathname, undefined, { shallow: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router?.query?.loggedOut, router?.query?.deletionRequest])

  const noQueryParams = isEmpty(router?.query) || router?.query?.theme

  useEffect(() => {
    if (inspectionOffer && noQueryParams && !offerNotificationInteracted) {
      removeAllNotificationToast()
      notify({
        variant: "success",
        content: offerNotificationFormattedText,
        autoclose: false,
        showOncePerSession: true,
        link: {
          link: ROUTES.serviceFlow({
            flowStep: serviceFlowSteps.inspection,
            flow: "2",
            inspectionOfferId: inspectionOffer?.id,
          }),
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionOffer?.id])

  return (
    <BasicLayout
      key="homePage"
      headerNavigation={headerNavigation}
      footerSection={footerSection}
      topBannerItem={topBannerItem}
    >
      <Meta {...mapContentfulPageMetadata(contentfulPage)} />
      {auth?.user && (
        <NextAppointmentHeader
          nextAppointment={bookingsInfo?.[0]}
          recommendation={recommendation ?? undefined}
          firstName={firstName}
        />
      )}
      <ContentfulPage data={contentfulPage} />
    </BasicLayout>
  )
}
