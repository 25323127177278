import { FC } from "react"

import { HeaderDesktop } from "src/components/core/header/headerDesktop"
import { HeaderMobile } from "src/components/core/header/headerMobile"
import { HeaderNavigationProps } from "src/types/HeaderNavigationProps"

type BasicLayoutHeaderProps = {
  headerNavigation?: HeaderNavigationProps[] | null
}

export const BasicLayoutHeader: FC<BasicLayoutHeaderProps> = ({
  headerNavigation,
}) => {
  return (
    <>
      <HeaderMobile
        showNavigation
        showShoppingCart
        headerNavigation={headerNavigation}
      />
      <HeaderDesktop headerNavigation={headerNavigation} showShoppingCart />
    </>
  )
}
