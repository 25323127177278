const rules = {
  ae: "ä",
  oe: "ö",
  ue: "ü",
  "-": " ",
}

export const convertToGermanCharacters = (value: string) => {
  value = value.toLowerCase()

  return Object.entries(rules).reduce(
    (result, [pattern, replacement]) =>
      result.replace(new RegExp(pattern, "g"), replacement),
    value,
  )
}
