import { FC } from "react"

import { BaseLink } from "src/components/common/base-link"

import { Icon } from "src/components/common/icon"
import { screenBreakpoints } from "src/const/screenBreakpoints"
import { create } from "src/helpers/bem"

import { Media } from "src/helpers/media"

import styles from "./Breadcrumbs.module.scss"

const bem = create(styles, "Breadcrumbs")

type Breadcrumb = {
  label: string
  url: string
}

type BreadcrumbsDesktopProps = {
  breadcrumbs: Breadcrumb[]
}

export const BreadcrumbsDesktop: FC<BreadcrumbsDesktopProps> = ({
  breadcrumbs,
}) => {
  if (breadcrumbs.length < 2) {
    return null
  }

  return (
    <Media minWidth={screenBreakpoints.md}>
      <nav aria-label="breadcrumb" className={bem("container")}>
        <ol className={bem("list")}>
          {breadcrumbs.map((breadcrumb, index) => {
            const isLastItem = index === breadcrumbs.length - 1

            return (
              <li
                key={index}
                className={bem("item", {
                  active: isLastItem,
                })}
              >
                {isLastItem ? (
                  <span aria-current="page">{breadcrumb.label}</span>
                ) : (
                  <>
                    <BaseLink href={breadcrumb.url}>
                      {breadcrumb.label}
                    </BaseLink>
                    <Icon
                      name="navNext"
                      className={bem("chevron-icon")}
                      aria-hidden="true"
                    />
                  </>
                )}
              </li>
            )
          })}
        </ol>
      </nav>
    </Media>
  )
}
