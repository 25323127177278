import { FC } from "react"

import {
  ContentfulRichText,
  ContentfulRichTextOptions,
} from "src/components/contentful/components/ContentfulRichText"
import { ContentfulRichTextData } from "src/types/contentful/autoservice"

export type CopyTextProps = {
  id?: string | null
  content?: ContentfulRichTextData | null
  options?: ContentfulRichTextOptions
  alignment?: "left" | "center" | null
  className?: string
}

/**
 * A rich text wrapper with specific styles, following the documentation provided in [Confluence](https://huk-autowelt.atlassian.net/wiki/spaces/Autowelt/pages/676691976/CCK+REL+003+Copy+Text)
 */
export const CopyText: FC<CopyTextProps> = ({
  content,
  options,
  alignment,
  className,
}) => {
  if (!content) {
    return null
  }

  return (
    <ContentfulRichText
      content={content}
      options={options}
      alignment={alignment}
      className={className}
    />
  )
}
