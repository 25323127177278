import { FC } from "react"

import { create } from "src/helpers/bem"

import styles from "./Breadcrumbs.module.scss"
import { BreadcrumbsDesktop } from "./BreadcrumbsDesktop"
import { BreadcrumbsMobile } from "./BreadcrumbsMobile"
import { Breadcrumb } from "./types"

const bem = create(styles, "Breadcrumbs")

type BreadcrumbsProps = {
  breadcrumbs: Breadcrumb[]
}

export const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs }) => {
  if (breadcrumbs.length < 2) {
    return null
  }

  return (
    <div className={bem()}>
      <BreadcrumbsDesktop breadcrumbs={breadcrumbs} />

      <BreadcrumbsMobile {...breadcrumbs[breadcrumbs.length - 2]} />
    </div>
  )
}
